<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
            
        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Roles</h4>
                  <div class="">
                    <router-link :to="{ name: 'createrole'}">
                      <button class="btn btn-default btn-orange">
                        <i class="fa fa-plus"></i> New Role
                      </button>
                    </router-link>
                  </div>
                </div>

                <!-- <div class="search_section">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="row">
                        <div class="col-md-6">
                          <input aria-invalid="false" v-model="search" @keyup="getBranches()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." type="search" class="form-control" > 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="table_section">
                      <table class="table  table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Slug</th>
                            <th scope="col">Created On</th>
                          </tr>
                        </thead>
                        <tbody v-if="!loading">
                          <tr v-for="role in roles" :key="role.id">
                            <td>
                              <router-link :to="{ name: 'updaterole', params: { id:role.id }}">
                                {{role.name}}
                              </router-link>
                            </td>
                            <td><span>{{role.slug}}</span></td>
                            <td>
                              {{formatDate(role.created_at)}}
                            </td>
                          </tr>
    
                        </tbody>
                        <tfoot v-if="!loading">
                          <tr>
                            <td colspan="8">
                              <div id="pagination" v-if="totalPages > 1">
                                <ul class="showItems">
                                  <li>Show Items:
                                    <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                      <option :value="10">10</option>
                                      <option :value="20">20</option>
                                      <option :value="30">30</option>
                                      <option :value="40">40</option>
                                      <option :value="50">50</option>
                                    </select>
                                  </li>
                                
                                </ul>
                                <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
    
                              </div>
                              
                            </td>
                          </tr>
                        </tfoot>
                      </table>
    
                    </div>
                    <div v-if="loading" class="loading-cover">
                      <div class="loader"> </div>
                    </div>
    
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Pagination from '@/pages/pagination/pagination.vue';
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            roles: [],
            loading: false,
            page: 1,
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            enterpageno: '',
            search:''
        }
    },
    created: async function () {
        this.getRoles();
    },
    methods: {
        async getRoles() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/roles`;
            this.loading = true;
            this.axios.get(url,config).then((response) => {
                if (response.status === 200) {
                    this.roles =  response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            }).finally(()=>  this.loading = false);

        },
    },
    mixins: [ReusableFunctionMixins,ReusableDataMixins]
}
</script>
